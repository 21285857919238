import React from "react";
import {
  ImageContent,
  postContentSplit,
} from "../../../Mono/tools/postExtract";
import { Post } from "../../../Mono/types/Post";
import { Colors } from "../../../Mono/consts/Colors";
import { Icon, IconType } from "../../assets/icons";
import { PostField } from "./PostField";
// import {FontVariants, Txt} from '../UI/Text';
import { formatDateToISO } from "../../../Mono/tools/formatDateToISO";
import { Box } from "../UI/Box";
import { Spacer } from "../UI/Spacer";
import { HStack } from "../UI/HStack";
import { Touchable } from "../UI/Touchable";
import { Text, TextVariants } from "../UI/Text";

interface CommentProps {
  post: Post;

  replyLine?: boolean;

  savePost: () => void;
  rePost: () => void;
  onClickProfile: () => void;
  onClickPost: () => void;

  onPressImage: (image: ImageContent) => void;
  onPressUrl: (url: string) => void;
}

export const Comment = ({
  post,
  replyLine,

  savePost,
  rePost,
  onClickProfile,
  onClickPost,
  onPressImage,
  onPressUrl,
}: CommentProps) => {
  const postContent = postContentSplit(post.content);

  return (
    <>
      <Touchable onPress={onClickPost}>
        <HStack>
          <Box spacing={10}>
            <Touchable onPress={onClickProfile}>
              {post.user ? (
                <img
                  alt="avatar"
                  src={post.user?.avatar}
                  height={40}
                  width={40}
                  style={{
                    borderRadius: 15,
                    backgroundColor: Colors.Black2,
                  }}
                />
              ) : (
                <Box
                  color={Colors.Black1}
                  height={40}
                  width={40}
                  borderRadius={15}
                />
              )}
            </Touchable>
            {replyLine && (
              <Box
                style={{
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "center",
                  height: "calc(100% - 60px)",
                }}
                spacing={{
                  top: 10,
                }}
              >
                <Box
                  width={2}
                  borderRadius={100}
                  style={{
                    marginTop: 10,
                    backgroundColor: Colors.Grey2,
                    alignSelf: "center",
                    height: "100%",
                  }}
                />
              </Box>
            )}
          </Box>
          <Box width="100%">
            <Spacer y={20} />

            <Touchable onPress={onClickProfile}>
              <HStack spaceBetween>
                <Box>
                  <Text
                    color={Colors.Grey}
                    size={TextVariants.Small}
                    text={post?.user ? post.user?.name : "[削除]"}
                  />
                </Box>
                <HStack>
                  <Text
                    color={Colors.Grey}
                    size={TextVariants.Smaller}
                    text={formatDateToISO(post.date)}
                  />
                  <Spacer x={10} />
                </HStack>
              </HStack>
            </Touchable>
            <Box
              spacing={{
                top: 10,
                bottom: 25,
              }}
            >
              <Box
                borderRadius={15}
                style={{
                  overflow: "hidden",
                }}
              >
                <PostField
                  postContent={postContent}
                  onPressImage={onPressImage}
                  onPressUrl={onPressUrl}
                  textSpacing={5}
                  textSize={15}
                />
              </Box>
            </Box>
          </Box>
        </HStack>

        <Box spacing={{ left: 10, right: 20 }}>
          <HStack spaceBetween>
            <Text
              color={Colors.Grey}
              size={TextVariants.Small}
              text={`${post.commentCount} コメ`}
            />

            <HStack>
              <Touchable onPress={rePost} enableOpacity>
                <Icon
                  size={20}
                  type={
                    post.isRePosted ? IconType.RepostGreen : IconType.RepostGrey
                  }
                />
              </Touchable>
              <Spacer x={20} />
              <Touchable onPress={savePost} enableOpacity>
                <Icon
                  size={20}
                  type={post.isSaved ? IconType.SaveRed : IconType.SaveGrey}
                />
              </Touchable>
            </HStack>
          </HStack>
        </Box>
      </Touchable>
    </>
  );
};
