import React from "react";
import { apiAuthoriseEmail } from "../../../Mono/API/user";
import {
  Box,
  Spacer,
  VStack,
  Text,
  Button,
  TextVariants,
  ButtonSizes,
} from "../../Components/UI";
import { Colors } from "../../../Mono/consts/Colors";
import {   TextInput } from "../../Components/UX";
import YuraguraWhiteWithName from "../../assets/YuraguraWhiteWithName";
import { LoadingOverlay } from "../../Components/UX/LoadingOverlay";

export const AuthenticateEmail = ({
  auth,
  refetch,
}: {
  auth: {
    token: string | null;
    logOut: () => void;
  };
  refetch: () => void;
}) => {
  const [authenticationCode, setAuthenticationCode] =
    React.useState<string>("");
  const onChangeAuthenticationCode = (value: string) => {
    if (value.length <= 4 && /^[0-9]*$/.test(value)) {
      setAuthenticationCode(value);
    }
  };
  const isAuthenticationCodeValid = authenticationCode.length === 4;
  const [loadingAuth, setLoadingAuth] = React.useState<boolean>(false);

  const onSubmitAuthenticationCode = () => {
    if (!auth.token || !isAuthenticationCodeValid) return;
    setLoadingAuth(true);
    apiAuthoriseEmail(auth.token)(authenticationCode)
      .then((r) => {
        if (r) {
          refetch();
          setLoadingAuth(false);
        }
      })
      .catch(() => {
        setLoadingAuth(false);
      });
  };

  return (
    <Box height="100vh" color={Colors.Black3}>
      <VStack
        center
        spaceBetween
        style={{
          flex: 1,
          height: "100%",
        }}
      >
        <Box>
          <Spacer y={100} />
          <VStack center>
            <YuraguraWhiteWithName style={{ width: 200, height: 100 }} />
          </VStack>
          <Spacer y={60} />
          <Text color={Colors.Grey} text="認証コードを入力してください" />
          <Spacer y={20} />

          <VStack>
            <TextInput
              value={authenticationCode}
              placeholder="0 0 0 0"
              onChange={onChangeAuthenticationCode}
              style={{
                textAlign: "center",
                fontSize: TextVariants.Regular,
              }}
            />
            <Spacer y={20} />
            {isAuthenticationCodeValid && (
              <Button
                bold
                text="送信"
                size={ButtonSizes.Medium}
                center
                onPress={onSubmitAuthenticationCode}
              />
            )}
          </VStack>
        </Box>
        <Box>
          <Button
            text="ログアウト"
            bold
            center
            size={ButtonSizes.Small}
            onPress={auth.logOut}
          />
          <Spacer y={20} />
        </Box>
      </VStack>
      <LoadingOverlay display={loadingAuth} />
    </Box>
  );
};
