import React from "react";
import {
  ImageContent,
  postContentSplit,
} from "../../../Mono/tools/postExtract";
import { Post } from "../../../Mono/types/Post";
import { Colors } from "../../../Mono/consts/Colors";
import { Icon, IconType } from "../../assets/icons";
import { PostField } from "./PostField";
import { formatDateToISO } from "../../../Mono/tools/formatDateToISO";
import { Box } from "../UI/Box";
import { Text, TextVariants } from "../UI/Text";
import { Spacer } from "../UI/Spacer";
import { HStack } from "../UI/HStack";
import { Touchable } from "../UI/Touchable";
import { VStack } from "../UI";

// import React from "react";
// import {
//   ImageContent,
//   postContentSplit,
// } from "../../../../Mono/tools/postExtract";
// import { Post } from "../../../../Mono/types/Post";
// import { Colors } from "../../../../Mono/consts/Colors";
// import { Icon, IconType } from "../../assets/icons";
// import { PostField } from "./PostField";
// import { Image } from "react-native";
// import { formatDateToISO } from "../../../../Mono/tools/formatDateToISO";
// import { Box } from "../UI/Box";
// import { Text, TextVariants } from "../UI/Text";
// import { Spacer } from "../UI/Spacer";
// import { HStack } from "../UI/HStack";
// import { Touchable } from "../UI/Touchable";

export const PostComponent = ({
  post,
  saved,
  savePost,

  rePost,
  isRePosted,
  onClickProfile,
  onPressImage,
  onPressUrl,
  onShare,
}: {
  post: Post;
  saved: boolean;
  savePost: (post: Post) => void;

  rePost: (post: Post) => void;
  isRePosted: boolean;

  onClickProfile: () => void;
  onPressImage: (image: ImageContent) => void;
  onPressUrl: (url: string) => void;
  onShare: () => void;
}) => {
  const postContent = postContentSplit(post.content);

  return (
    <>
      <Box>
        <Box>
          <HStack spaceBetween center>
            <HStack center>
              <Spacer x={10} />
              <Touchable onPress={onClickProfile}>
                <HStack center>
                  {post.user ? (
                    <img
                      src={post.user?.avatar}
                      style={{
                        borderRadius: 15,
                        width: 40,
                        height: 40,
                        backgroundColor: Colors.Black2,
                      }}
                    />
                  ) : (
                    <Box
                      color={Colors.Black1}
                      height={40}
                      width={40}
                      borderRadius={15}
                    />
                  )}
                  <Spacer x={10} />
                  <Box center>
                    <VStack>
                      <Text
                        color={Colors.Grey}
                        size={TextVariants.Small}
                        text={post.user?.name || "[削除]"}
                      />
                      <Spacer y={2} />
                      <Text
                        color={Colors.Grey}
                        size={TextVariants.Small}
                        text={
                          post.user?.hangName
                            ? `@${post.user?.hangName}`
                            : "@ --"
                        }
                      />
                    </VStack>
                  </Box>
                </HStack>
              </Touchable>
            </HStack>
            <HStack>
              <Text
                color={Colors.Grey}
                size={TextVariants.Smaller}
                text={formatDateToISO(post.date)}
              />
              <Spacer x={10} />
            </HStack>
          </HStack>
        </Box>
      </Box>
      <Spacer y={10} />
      <Box
        borderRadius={15}
        style={{
          overflow: "hidden",
        }}
      >
        <PostField
          postContent={postContent}
          onPressImage={onPressImage}
          onPressUrl={onPressUrl}
        />
      </Box>
      <Spacer y={10} />
      <Box>
        <Box>
          <HStack>
            <Box spacing={10} width="95%">
              <HStack spaceBetween>
                <HStack center>
                  <Spacer x={10} />
                  {post.saveCount ? (
                    <>
                      <Text
                        color={Colors.White}
                        size={TextVariants.Small}
                        text={post.saveCount}
                      />
                      <Spacer x={5} />
                      <Text
                        color={Colors.Grey}
                        size={TextVariants.Smaller}
                        text="保存"
                      />
                      <Spacer x={10} />
                    </>
                  ) : null}

                  {post.rePostCount ? (
                    <>
                      <Text
                        color={Colors.White}
                        size={TextVariants.Small}
                        text={post.rePostCount}
                      />
                      <Spacer x={5} />
                      <Text
                        color={Colors.Grey}
                        size={TextVariants.Smaller}
                        text="リピート"
                      />
                      <Spacer x={10} />
                    </>
                  ) : null}

                  {post.commentCount ? (
                    <>
                      <Text
                        color={Colors.White}
                        size={TextVariants.Small}
                        text={post.commentCount}
                      />
                      <Spacer x={5} />
                      <Text
                        color={Colors.Grey}
                        size={TextVariants.Smaller}
                        text="コメ"
                      />
                      <Spacer x={10} />
                    </>
                  ) : null}
                </HStack>
                <HStack>
                  {/* <Box center>
                    <Touchable onPress={onShare} enableOpacity>
                      <Icon size={23} type={IconType.ShareGrey} />
                    </Touchable>
                  </Box> */}
                  <Box width={20} />
                  <Box center>
                    <Touchable onPress={() => rePost(post)} enableOpacity>
                      <Icon
                        size={23}
                        type={
                          isRePosted
                            ? IconType.RepostGreen
                            : IconType.RepostGrey
                        }
                      />
                    </Touchable>
                  </Box>
                  <Box width={20} />
                  <Box center>
                    <Touchable onPress={() => savePost(post)} enableOpacity>
                      <Icon
                        size={23}
                        type={saved ? IconType.SaveRed : IconType.SaveGrey}
                      />
                    </Touchable>
                  </Box>
                </HStack>
              </HStack>
            </Box>
          </HStack>
        </Box>
      </Box>
    </>
  );
};
