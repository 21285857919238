import { MasonryComponent } from "../../Components/UX/Masonry";
import React from "react";
import { useLinkTo } from "../../../hooks/useLinkTo";
import { RouteName } from "../../../Routes";
import { HeadBanner, MasonryLoadingComponent } from "../../Components/UX";
import { bannerUri } from "../../../Mono/consts/bannerUri";
import {
  Box,
  HStack,
  Spacer,
  Text,
  TextVariants,
  Touchable,
} from "../../Components/UI";
import { Icon, IconType } from "../../assets/icons";
import { useGetPopularPosts, useGetTagsSaved } from "../../../Mono/queryHooks";
import { PageContainer } from "../../Components/UX/Layout/PageContainer";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import { Post } from "../../../Mono/types";
import { Colors } from "../../../Mono/consts/Colors";

export const Explore = () => {
  const mode = useGetWindowMode();
  const { posts, getMore } = useGetPopularPosts();

  React.useEffect(() => {
    if (posts && posts?.length < 20) {
      getMore();
    }
  }, [posts]);

  const { val: savedTags } = useGetTagsSaved();
  const tagIsSaved = savedTags?.map((t) => t.tag);

  const linkTo = useLinkTo();
  const onClickPost = (post: Post) => {
    linkTo(RouteName.Post, { postId: post.id });
  };

  const navigateToSearch = () => linkTo(RouteName.Search);
  const navigateToTag = (tag: string) => linkTo(RouteName.Tag, { tag });

  return (
    <PageContainer onEndReached={getMore}>
      <Box center>
        <Box style={{ position: "relative", maxWidth: 1100, width: "100%" }}>
          <Box spacing={10}>
            <HeadBanner uri={bannerUri} height={mode === "web" ? 400 : 225} />
          </Box>
          <Box style={{ position: "absolute", top: 0, right: 0, zIndex: 2000 }}>
            <Touchable onPress={navigateToSearch}>
              <Box spacing={40}>
                <Icon type={IconType.Search} size={22} />
              </Box>
            </Touchable>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          marginTop: mode === "web" ? -40 : 0,
        }}
      >
        {tagIsSaved?.length ? (
          <>
            <Box center>
              <Box
                spacing={
                  mode === "web"
                    ? { left: 15, right: 15 }
                    : {
                        left: 10,
                        right: 10,
                      }
                }
                style={{
                  width: "96%",
                  maxWidth: 1000,
                  zIndex: 1,
                  marginTop: mode === "web" ? -40 : 0,
                }}
              >
                <Box
                  style={{
                    overflow: "auto",
                    whiteSpace: "nowrap",
                    display: "flex",
                    flexDirection: "row",
                    scrollbarWidth: "none",
                  }}
                >
                  {tagIsSaved?.map((tag) => (
                    <Box
                      spacing={{
                        left: 5,
                        right: 5,
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <Touchable onPress={() => navigateToTag(tag)}>
                        <Box color={Colors.Black1} borderRadius={15}>
                          <Box
                            spacing={{
                              left: 15,
                              right: 15,
                              top: 10,
                              bottom: 10,
                            }}
                          >
                            <HStack center>
                              <Icon type={IconType.Hashtag} size={17} />
                              <Spacer x={5} />
                              <Text bold size={TextVariants.Small}>
                                {tag}
                              </Text>
                            </HStack>
                          </Box>
                        </Box>
                      </Touchable>
                    </Box>
                  ))}
                </Box>
                {/* </ScrollView> */}
              </Box>
            </Box>

            <Spacer y={10} />
          </>
        ) : (
          <Spacer y={15} />
        )}
      </Box>
      <Box center>
        <Box
          spacing={
            mode === "web"
              ? { left: 15, right: 15 }
              : {
                  left: 10,
                  right: 10,
                }
          }
          style={{
            width: "100%",
            maxWidth: 1000,
            zIndex: 1,
          }}
        >
          {posts ? (
            <MasonryComponent posts={posts} onClick={onClickPost} />
          ) : (
            <MasonryLoadingComponent />
          )}
        </Box>
      </Box>
    </PageContainer>
  );
};
