import React from "react";
import { MasonryComponent } from "../../Components/UX/Masonry";

import "react-indiana-drag-scroll/dist/style.css";
import { PageContainer } from "../../Components/UX/Layout/PageContainer";
import { MasonryLoadingComponent, Tabs } from "../../Components/UX";
import {
  useGetFollowingPosts,
  useGetSuggestedTags,
  useGetTagsPosts,
} from "../../../Mono/queryHooks";
import { useLinkTo } from "../../../hooks/useLinkTo";
import { RouteName } from "../../../Routes";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import { Box, Spacer } from "../../Components/UI";
import { Post } from "../../../Mono/types";

export const Home = () => {
  const linkTo = useLinkTo();
  const mode = useGetWindowMode();

  const [selectedTag, setSelectedTag] = React.useState<
    "recommended" | "follows"
  >("recommended");

  const { val: tags } = useGetSuggestedTags();

  const { posts: tagsPosts, getMore: getMoreTagsPosts } = useGetTagsPosts(
    tags?.map((i) => i.tag) || undefined
  );

  const { posts: followingPosts, getMore: getMoreFollowingPosts } =
    useGetFollowingPosts();

  const posts = selectedTag === "follows" ? followingPosts : tagsPosts;

  const loading = !tags || !posts || !tagsPosts;

  const onEndReached = () =>
    selectedTag === "follows" ? getMoreFollowingPosts() : getMoreTagsPosts();

  React.useEffect(() => {
    if (posts && posts?.length < 20) {
      onEndReached();
    }
  }, [posts]);

  const onClickPost = (post: Post) => {
    linkTo(RouteName.Post, {
      postId: post.id,
    });
  };

  const onTagSelected = (tag: string) => {
    setSelectedTag(tag as "recommended" | "follows");
  };

  return (
    <PageContainer onEndReached={onEndReached}>
      <Spacer y={mode === "web" ? 110 : 10} />
      <Box center>
        <Box style={{ position: "relative", maxWidth: 1000, width: "100%" }}>
          <Tabs
            tabs={[
              {
                label: "おすすめ",
                value: "recommended",
              },
              ...(followingPosts?.length
                ? [
                    {
                      label: "フォロー",
                      value: "follows",
                    },
                  ]
                : []),
            ]}
            selectedTab={selectedTag}
            setSelectedTab={onTagSelected}
          />
        </Box>
      </Box>
      <Spacer y={20} />

      <Box center>
        <Box
          spacing={
            mode === "web"
              ? { left: 15, right: 15 }
              : {
                  left: 10,
                  right: 10,
                }
          }
          style={{
            width: "100%",
            maxWidth: 1000,
            zIndex: 1,
          }}
        >
          {loading ? (
            <MasonryLoadingComponent />
          ) : posts?.length ? (
            <MasonryComponent posts={posts} onClick={onClickPost} />
          ) : null}
        </Box>
      </Box>
    </PageContainer>
  );
};
