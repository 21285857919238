import { useNavigate, useParams } from "react-router-dom";
import { MasonryComponent } from "../../Components/UX/Masonry";
import React from "react";
import { useScrollEnd } from "../../../hooks/useScrollEnd";
import { useLinkTo } from "../../../hooks/useLinkTo";
import { RouteName } from "../../../Routes";
import {
  useGetUserByHangName,
  useGetUserPosts,
} from "../../../Mono/queryHooks";
import service from "../../../Mono/service";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import { ProfileInfo } from "../../Components/UX";
import { Box, ButtonVariants, Spacer, Touchable } from "../../Components/UI";
import { PageContainer } from "../../Components/UX/Layout/PageContainer";
import { ProfileInfoLoading } from "../../Components/UX/ProfileInfoLoading";
import { Icon, IconType } from "../../assets/icons";
import { useBottomSheet } from "../../BottomSheet/BottomSheetContext";
import { Post } from "../../../Mono/types";

export const UserProfile = () => {
  let { hangName } = useParams();

  const mode = useGetWindowMode();
  const linkTo = useLinkTo();

  const isLoggedInUserProfile =
    service.state.user?.hangName === hangName || hangName === "me";
  const hangNameToUse = isLoggedInUserProfile
    ? service.state.user?.hangName
    : hangName;

  const bottomSheet = useBottomSheet();
  const navigate = useNavigate();

  const { val: user, reload } = useGetUserByHangName(hangNameToUse || "");

  const { posts, getMore } = useGetUserPosts(user?.id || "");
  const { isAtEnd } = useScrollEnd(2);

  React.useEffect(() => {
    if (isAtEnd) getMore();
  }, [isAtEnd]);

  const onFollow = () => {
    service
      .api()
      .follows.createFollow(user?.id || "")
      .then(() => {
        reload();
      });
  };

  const onUnFollow = () => {
    service
      .api()
      .follows.deleteFollow(user?.id || "")
      .then(() => {
        reload();
      });
  };

  const onClickPost = (post: Post) => {
    linkTo(RouteName.Post, { postId: post.id });
  };

  const onClickSettings = () => {
    bottomSheet.openSettingsModal({
      navigate,
    });
  };

  const onClickEditUser = () => {
    navigate("/users/profile/edit");
  };

  const onUnBlockUser = () => {
    service
      .api()
      .blocks.deleteBlock(user?.id || "")
      .then(() => {
        reload();
      });
  };

  const profileButtonText = isLoggedInUserProfile
    ? "プロフィール編集"
    : user?.isBlocked
    ? "ブロックを解除"
    : user?.isFollowing
    ? "✓ フォロー中"
    : "フォロー";

  const onProfileButtonClick = () =>
    isLoggedInUserProfile
      ? onClickEditUser()
      : user?.isBlocked
      ? onUnBlockUser()
      : user?.isFollowing
      ? onUnFollow()
      : onFollow();

  const buttonVariant = isLoggedInUserProfile
    ? ButtonVariants.Dark
    : user?.isBlocked
    ? ButtonVariants.White
    : user?.isFollowing
    ? ButtonVariants.Dark
    : ButtonVariants.Pink;

  const onClickTripleDot = !isLoggedInUserProfile
    ? () => {
        if (!user) return;
        bottomSheet.openUserModal({
          userId: user.id,
          isBlocked: user?.isBlocked,
          onBlockUser: reload,
        });
      }
    : undefined;

  const openLink = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <PageContainer onEndReached={getMore}>
      {isLoggedInUserProfile ? (
        <Box
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        >
          <Touchable onPress={onClickSettings}>
            <Box
              borderRadius={20}
              spacing={20}
              style={{
                position: "absolute",
                top: mode === "web" ? 20 : 5,
                right: 10,
                padding: 10,
                margin: 10,
              }}
            >
              <Icon type={IconType.TripleLine} size={30} />
            </Box>
          </Touchable>
        </Box>
      ) : null}
      <Spacer y={mode === "web" ? 110 : 10} />
      <Box center>
        <Box
          style={{
            width: "95%",

            maxWidth: 900,
          }}
        >
          {user ? (
            <ProfileInfo
              user={user}
              profileButtonText={profileButtonText}
              buttonVariant={buttonVariant}
              onProfileButtonClick={onProfileButtonClick}
              onClickTripleDot={onClickTripleDot}
              bannerHeight={mode === "web" ? 250 : 150}
              onPressUrl={openLink}
            />
          ) : (
            <ProfileInfoLoading bannerHeight={mode === "web" ? 250 : 150} />
          )}
        </Box>
      </Box>
      <Spacer y={10} />
      <Box center>
        <Box
          spacing={
            mode === "web"
              ? { left: 15, right: 15 }
              : {
                  left: 10,
                  right: 10,
                }
          }
          style={{
            width: "100%",
            maxWidth: 1000,
            zIndex: 1,
          }}
        >
          {posts ? (
            <MasonryComponent posts={posts} onClick={onClickPost} />
          ) : null}
        </Box>
      </Box>
    </PageContainer>
  );
};
