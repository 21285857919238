import React from "react";
import { Box } from "../UI/Box";
import { Colors } from "../../../Mono/consts/Colors";
import YuraguraWhiteWithName from "../../assets/YuraguraWhiteWithName";

export const HeadBanner = ({
  uri,
  height = 225,
}: {
  uri: string;
  height?: React.CSSProperties["height"];
}) => (
  <Box height={height} borderRadius={20} center>
    <img
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: 20,
        backgroundColor: Colors.Black2,
      }}
      src={uri}
      alt={"head-banner"}
    />
    <Box
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    />
    <YuraguraWhiteWithName
      style={{
        alignSelf: "center",
        position: "absolute",
        width: 180,
        height: 90,
      }}
    />
  </Box>
);
