import React from "react";

import { Box, Spacer } from "../UI";

import { Post } from "../../../Mono/types/Post";
import { Colors } from "../../../Mono/consts/Colors";
import { Comment } from "./Comment";
import { User } from "../../../Mono/types/User";
import { ImageContent } from "../../../Mono/tools/postExtract";

export const CommentsList = ({
  posts,
  commentsOpen,

  savePost,
  rePost,
  onClickProfile,
  onClickPost,
  onPressImage,
  onPressUrl,
}: {
  posts: Post[];
  commentsOpen?: boolean;

  savePost: (post: Post) => void;
  rePost: (post: Post) => void;
  onClickProfile: (user: User) => void;
  onClickPost: (post: Post) => void;
  onPressImage: (image: ImageContent) => void;
  onPressUrl: (url: string) => void;
}) => {
  const topThreeReplies = posts.slice(0, 3);

  return (
    <Box>
      {posts.length ? (
        <>
          <Spacer y={20} />
          <Box color={Colors.Grey2} height={1} />
          <Spacer y={10} />
        </>
      ) : null}
      {(commentsOpen ? posts : topThreeReplies).map((post) => (
        <Box key={post.id}>
          <Comment
            post={post}
            savePost={() => savePost(post)}
            rePost={() => rePost(post)}
            onClickProfile={() =>
              post.user ? onClickProfile(post.user) : null
            }
            onClickPost={() => onClickPost(post)}
            onPressImage={onPressImage}
            onPressUrl={onPressUrl}
          />
          <Spacer y={20} />
          <Box color={Colors.Grey2} height={1} />
          <Spacer y={10} />
        </Box>
      ))}
    </Box>
  );
};
