import React from "react";
import {
  Box,
  Button,
  ButtonSizes,
  HStack,
  Spacer,
  Text,
  TextVariants,
  Touchable,
  VStack,
} from "../../Components/UI";
import { Colors } from "../../../Mono/consts/Colors";
import { Icon, IconType } from "../../assets/icons";
import { TextArea } from "../../Components/UX";
import service from "../../../Mono/service";
import { FileUploadComponent } from "../../Components/UX/FileUplaodComponent";

interface CreatePostProps {
  onSubmit: () => void;
  postFormContent: any[];
  setPostFormContent: (content: any[]) => void;
  addImage: (file: File) => void;
  addTag: () => void;
  tags: string[];
  replyToPost?: any;
  goBack: () => void;
  contentIsSubmittable: boolean;
  nsfw: boolean;
  setNsfw: (nsfw: boolean) => void;
}

export const CreatePostContent: React.FC<CreatePostProps> = ({
  onSubmit,
  postFormContent,
  setPostFormContent,
  addImage,
  addTag,
  tags,
  replyToPost,
  nsfw,
  setNsfw,
}) => {
  return (
    <>
      {replyToPost ? (
        <Box spacing={25}>
          <Text color={Colors.Grey}>
            @{replyToPost.user?.hangName} へリプライ
          </Text>
        </Box>
      ) : null}
      {postFormContent.map((content, index) => (
        <Box key={index}>
          {content.type === "image" ? (
            <FormImageComponent
              content={content}
              deleteImage={() =>
                setPostFormContent(postFormContent.filter((c) => c !== content))
              }
            />
          ) : content.type === "string" ? (
            <VStack>
              <FormTextComponent
                content={content}
                onTextChange={(text) => {
                  setPostFormContent(
                    postFormContent.map((c) =>
                      c === content ? { ...c, content: text } : c
                    )
                  );
                }}
              />
            </VStack>
          ) : null}
          <Spacer y={20} />
        </Box>
      ))}
      <HeaderTools
        onClickAddImage={addImage}
        onClickAddTag={addTag}
        nsfw={nsfw}
        onClickNsfw={() => setNsfw(!nsfw)}
      />

      <Touchable onPress={addTag}>
        <TagPills tags={tags} />
      </Touchable>

      <Box spacing={50}>
        <Button
          text="投稿"
          center
          size={ButtonSizes.Large}
          onPress={onSubmit}
        />
      </Box>
      <Spacer y={250} />
    </>
  );
};

const imageDeleteStyle: React.CSSProperties = {
  position: "absolute",
  top: 10,
  left: 10,
  height: 23,
  width: 23,
  alignItems: "center",
  borderColor: Colors.Grey,
  borderStyle: "solid",
  borderWidth: 1,
  zIndex: 10,
};

const FormImageComponent: React.FC<{
  content: any;
  deleteImage: () => void;
}> = ({ content, deleteImage }) => (
  <Box style={{ position: "relative" }}>
    <img
      src={URL.createObjectURL(content.image)}
      style={{
        aspectRatio: content.image?.width / content.image?.height,
        width: "100%",
      }}
    />
    <Box style={imageDeleteStyle} color={Colors.Black1} borderRadius={9} center>
      <Touchable onPress={deleteImage}>
        <VStack>
          <Icon type={IconType.X} size={17} />
        </VStack>
      </Touchable>
    </Box>
  </Box>
);

const textAreaStyle: React.CSSProperties = {
  padding: 25,
  paddingTop: 30,
  backgroundColor: Colors.Black3,
};

const FormTextComponent: React.FC<{
  content: any;
  onTextChange: (text: string) => void;
}> = ({ content, onTextChange }) => (
  <TextArea
    placeholder="ポスト..."
    style={textAreaStyle}
    value={content.content}
    onChange={onTextChange}
  />
);

const HeaderTools = ({
  onClickAddImage,
  onClickAddTag,
  nsfw,
  onClickNsfw,
}: {
  onClickAddImage: (file: File) => void;
  onClickAddTag: () => void;
  nsfw?: boolean;
  onClickNsfw: () => void;
}) => (
  <HStack
    style={{
      justifyContent: "flex-end",
    }}
    center
  >
    {service.state?.user?.nsfw ? (
      <>
        <Touchable onPress={onClickNsfw}>
          <Box color={Colors.Black1} center borderRadius={15}>
            <Box spacing={10}>
              <HStack center>
                <Icon size={25} type={nsfw ? IconType.Check : IconType.X} />
                <Spacer x={5} />
                <Text
                  bold
                  size={TextVariants.Smaller}
                  text="NSFW"
                  color={nsfw ? Colors.White : Colors.Grey}
                />
              </HStack>
            </Box>
          </Box>
        </Touchable>
        <Box width={20} />
      </>
    ) : null}
    <FileUploadComponent
      onFileUpload={onClickAddImage}
      component={(onButtonClick) => (
        <Touchable onPress={onButtonClick}>
          <Box color={Colors.Black1} center borderRadius={15}>
            <Box spacing={10}>
              <Icon size={25} type={IconType.Image} />
            </Box>
          </Box>
        </Touchable>
      )}
    />
    <Box width={20} />
    <Touchable onPress={onClickAddTag}>
      <Box color={Colors.Black1} center borderRadius={15}>
        <Box spacing={10}>
          <Icon size={25} type={IconType.Hashtag} />
        </Box>
      </Box>
    </Touchable>
    <Spacer x={20} />
  </HStack>
);

const TagPills = ({ tags }: { tags: string[] }) => (
  <HStack
    style={{
      flexWrap: "wrap",
    }}
  >
    {tags.map((tag) => (
      <Box key={tag} spacing={5}>
        <Box spacing={5} color={Colors.Black1} borderRadius={15}>
          <Box
            spacing={{
              left: 7,
              right: 7,
              top: 5,
              bottom: 5,
            }}
          >
            <HStack center>
              <Icon type={IconType.HashtagGrey} size={15} />
              <Spacer x={5} />
              <Text
                color={Colors.Grey}
                size={TextVariants.Smaller}
                text={tag}
              />
            </HStack>
          </Box>
        </Box>
      </Box>
    ))}
  </HStack>
);
