import { Route, Routes } from "react-router-dom";
import { Explore } from "../Content/views/Explore";
import { Home } from "../Content/views/Home";
import { UserProfile } from "../Content/views/Profile/UserProfile";
import { PostPage } from "../Content/views/Post";
import { LoginPage } from "../Content/views/Login";
import { Tag } from "../Content/views/Tag";
import { Notification } from "../Content/views/Notification";
import { ProfileEdit } from "../Content/views/Profile/UserProfileEdit";
import { SavedPosts } from "../Content/views/Settings/SavedPosts";
import { AccountSettings } from "../Content/views/Settings/AccountSettings";
import { BlockedUsers } from "../Content/views/Settings/BlockedUsers";
import { EditHangName } from "../Content/views/Settings/EditHangName";
import { Terms } from "../Content/views/Settings/Terms";
import { DeleteUser } from "../Content/views/Settings/DeleteUser";
import { Contact } from "../Content/views/Settings/Contact";

import { CreatePost } from "../Content/views/CreatePost";
import { Search } from "../Content/views/Search";
import service from "../Mono/service";
import { useAuth } from "../hooks/useAuth";
import React from "react";
import { User } from "../Mono/types";
import { AuthenticateEmail } from "../Content/views/Login/AuthenticateEmail";
import { UserReplies } from "../Content/views/Settings/UserReplies";
import { iosAppUri } from "../Mono/consts/iosAppUri";
import { androidAppUri } from "../Mono/consts/iosAppUri copy";
import { BootScreen } from "../Content/Components/UX/BootScreen";
import { WebNavComponent } from "../Content/Components/UX/WebNav";

export type RouteType = {
  ROUTE: RouteName;
  PAGE: React.FC;
};

export enum RouteName {
  Home = "/home",
  Explore = "/",
  Profile = "/users/:hangName",
  ProfileEdit = "/users/profile/edit",
  Post = "/posts/:id/:pagination?",
  Tag = "/tag/:tag",
  Notifications = "/notifications",
  Search = "/search",
  UserReplies = "/user-replies",

  SavedPosts = "/saved",
  AccountSettings = "/settings",
  EditHangName = "/settings/hangname",
  BlockedUsers = "/settings/blocked",
  DeleteUser = "/settings/delete",
  Terms = "/settings/terms",
  Contact = "/settings/contact",
  CreatePost = "/create-post/:replyToId?",
  ToIosApp = "/ios",
  ToAndroidApp = "/android",
}

const RedirectToAppStore = () => {
  React.useEffect(() => {
    window.location.href = iosAppUri;
  }, []);

  return null;
};

const RedirectToGooglePlay = () => {
  React.useEffect(() => {
    window.location.href = androidAppUri;
  }, []);

  return null;
};

export const PAGES: RouteType[] = [
  {
    ROUTE: RouteName.Explore,
    PAGE: Explore,
  },
  {
    ROUTE: RouteName.Home,
    PAGE: Home,
  },
  {
    ROUTE: RouteName.Profile,
    PAGE: UserProfile,
  },
  {
    ROUTE: RouteName.ProfileEdit,
    PAGE: ProfileEdit,
  },
  {
    ROUTE: RouteName.Post,
    PAGE: PostPage,
  },
  {
    ROUTE: RouteName.Tag,
    PAGE: Tag,
  },
  {
    ROUTE: RouteName.Notifications,
    PAGE: Notification,
  },
  {
    ROUTE: RouteName.SavedPosts,
    PAGE: SavedPosts,
  },
  {
    ROUTE: RouteName.AccountSettings,
    PAGE: AccountSettings,
  },
  {
    ROUTE: RouteName.EditHangName,
    PAGE: EditHangName,
  },
  {
    ROUTE: RouteName.BlockedUsers,
    PAGE: BlockedUsers,
  },
  {
    ROUTE: RouteName.UserReplies,
    PAGE: UserReplies,
  },
  {
    ROUTE: RouteName.DeleteUser,
    PAGE: DeleteUser,
  },
  {
    ROUTE: RouteName.Terms,
    PAGE: Terms,
  },
  {
    ROUTE: RouteName.Contact,
    PAGE: Contact,
  },
  {
    ROUTE: RouteName.CreatePost,
    PAGE: CreatePost,
  },
  {
    ROUTE: RouteName.Search,
    PAGE: Search,
  },
  {
    ROUTE: RouteName.ToIosApp,
    PAGE: RedirectToAppStore,
  },
  {
    ROUTE: RouteName.ToAndroidApp,
    PAGE: RedirectToGooglePlay,
  },
];

const requiresAuth = [
  RouteName.Home,
  // RouteName.Profile,
  RouteName.ProfileEdit,
  RouteName.Notifications,
  RouteName.SavedPosts,
  RouteName.AccountSettings,
  RouteName.EditHangName,
  RouteName.DeleteUser,
  RouteName.BlockedUsers,
];

const checkRequiresAuth = (route: RouteName) => {
  return requiresAuth.includes(route);
};

const intervalUserRetrieval = (userId: string): Promise<User> =>
  new Promise((resolve, reject) => {
    let interval = 0;

    const getUser = async () =>
      setTimeout(() => {
        service
          .api()
          .users.getUserById(userId)
          .then((res) => {
            resolve(res);
          })
          .catch(() => {
            interval++;

            if (interval < 3) {
              getUser();
              return;
            }
            reject();
          });
      }, 3000);

    getUser();
  });

const useGetUser = () => {
  const auth = useAuth();
  const [user, setUser] = React.useState<User | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (auth?.user?.uid) {
      intervalUserRetrieval(auth.user.uid).then((res) => {
        setUser(res);
        setLoading(false);
      });
    }

    setUser(null);
  }, [auth.user?.uid]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const refetch = async () => {
    if (auth.user?.uid) {
      return service
        .api()
        .users.getUserById(auth.user.uid)
        .then((res) => {
          setUser(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return { user, auth, refetch, loading };
};

export const AppRoutes = () => {
  const { user, auth, refetch, loading } = useGetUser();

  if (loading) {
    return <BootScreen />;
  }

  if (user && !user?.emailAuthenticated) {
    return <AuthenticateEmail auth={auth} refetch={refetch} />;
  }

  return (
    <>
      <WebNavComponent />
      <Routes>
        {PAGES.map((PAGE) => (
          <Route
            key={PAGE.ROUTE}
            path={PAGE.ROUTE}
            element={
              auth.user || !checkRequiresAuth(PAGE.ROUTE) ? (
                <PAGE.PAGE />
              ) : (
                <LoginPage />
              )
            }
          />
        ))}
      </Routes>
    </>
  );
};
