import React from "react";

import { BottomSheet } from "./BottomSheet";
import {
  PostModalProps,
  SettingsModalProps,
  UserModalProps,
  postModal,
  settingsModal,
  userModal,
} from "./bottomSheetOptions";
import firebase from "firebase/compat/app";

interface BottomSheetContextProps {
  display: boolean;
  openPostModal: (params: PostModalProps) => void;
  openUserModal: (params: UserModalProps) => void;
  openSettingsModal: (params: SettingsModalProps) => void;
}

const BottomSheetContext = React.createContext<BottomSheetContextProps>({
  display: false,
  openPostModal: () => null,
  openUserModal: () => null,
  openSettingsModal: () => null,
});

const logOut = async () => firebase.app().auth().signOut();

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [display, setDisplay] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<React.ReactNode>(null);

  const closeModal = () => {
    setDisplay(false);
    setForm(null);
  };

  const openPostModal = (params: PostModalProps) => {
    setDisplay(true);
    setForm(
      postModal({
        params,
        onClose: closeModal,
        onDeletePost: () => {
          closeModal();
          params.onDeletePost();
        },
      })
    );
  };

  const openUserModal = (params: UserModalProps) => {
    setDisplay(true);
    setForm(
      userModal({
        params,
        onClose: closeModal,
      })
    );
  };

  const openSettingsModal = (params: SettingsModalProps) => {
    setDisplay(true);
    setForm(
      settingsModal({
        params,
        onLogOut: logOut,
        onClose: closeModal,
      })
    );
  };

  const modalOptions = {
    display,
    openPostModal,
    openUserModal,
    openSettingsModal,
  };

  return (
    <BottomSheetContext.Provider value={modalOptions}>
      {children}
      <BottomSheet display={display} closeModal={closeModal}>
        {form}
      </BottomSheet>
    </BottomSheetContext.Provider>
  );
};
export const useBottomSheet = () => React.useContext(BottomSheetContext);
