import React from "react";
import firebase from "firebase/compat/app";
import service from "../Mono/service";

const fiftyMins = 3000000;

function checkIsMoreThanFiftyMinsApart(date1: Date, date2: Date) {
  const difference = Math.abs(date1.getTime() - date2.getTime());
  return difference > fiftyMins;
}

export const useAuth = () => {
  const [user, setUser] = React.useState<any | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [token, setToken] = React.useState<string | null>(null);
  const [_, setLastTokenRefresh] = React.useState<Date>(new Date());

  React.useEffect(() => {
    const interval = setInterval(() => {
      (async () => {
        const currentTime = new Date();
        setLastTokenRefresh((time) => {
          const isMoreThanFiftyMinsApart = checkIsMoreThanFiftyMinsApart(
            currentTime,
            time
          );

          if (isMoreThanFiftyMinsApart) {
            firebase
              .app()
              .auth()
              .currentUser?.getIdToken()
              .then((i) => {
                service.updateToken(String(i));
                setToken(String(i));

                setLastTokenRefresh(new Date());
              });
          }

          return time;
        });
      })();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user?.uid) {
        setUser(user);
        await firebase
          .app()
          .auth()
          .currentUser?.getIdToken(true)
          ?.then((i) => {
            setTimeout(() => {
              service.updateToken(String(i));
              setToken(String(i));
              setLastTokenRefresh(new Date());
              setLoading(false);
            }, 2500);
          });
        return;
      }

      setUser(user);
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const logOut = async () =>
    firebase
      .app()
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
      });

  const forgotPassword = async (email: string) =>
    firebase.auth().sendPasswordResetEmail(email);

  return {
    user,
    token,
    logOut,
    forgotPassword,
    loading,
  };
};
